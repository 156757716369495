import { Component, EventEmitter, Injectable, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { BehaviorSubject, Subscription } from 'rxjs'
import { distinctUntilChanged } from 'rxjs/operators'
import { ParseService } from 'src/app/parse.service'
import { ChiliColor } from 'src/app/shared/types/chili/chili.editor'
import { throttleTimeLeadingAndTrailing } from 'src/utils'
import { ChiliColorAll } from '../types'
import { ColorUtilsService } from '../utils/color-utils.service'

interface ColorInUse {
    type: 'IN USE'
    chili: ChiliColor
    isNone: boolean
}

interface ColorQuickPick {
    type: 'QUICK'
    rgb: { r: number; b: number; g: number }
}

interface SliderInput {
    name: string
    value: number
}

interface SliderOutput {
    color: ChiliColorAll
    rgbHex: string
}

interface Slider {
    // inputs field is still required for hex slider to compute the output
    // it can be removed though and use the hexInput instead if inputs is undefined
    inputs: SliderInput[]
    hexInput: string | undefined
    min: number // 0 | undefined;
    max: number // 100 | 255 | undefined;
    mode: ColorPickerModes
}

// Check if the output for each be shared
export interface SliderValues {
    readonly cmyk: Slider
    readonly rgb: Slider
    readonly hex: Slider
    readonly all: ReadonlyArray<Slider>
}
type ColorPickerModes = 'rgb' | 'cmyk' | 'hex'

/**
 * Delegate class to encapsulate custom color picker
 */
@Injectable()
export class CustomPickerService {
    readonly colorOutput = new BehaviorSubject<ChiliColorAll | undefined>(undefined)

    readonly sliders: SliderValues = this.getSliderValues()

    // public hexColorInput = '#ffffff'

    // value should correspond to the keys of the sliders object
    private _selectedSlider: ColorPickerModes = 'cmyk'
    public get selectedSlider(): ColorPickerModes {
        return this._selectedSlider
    }
    public set selectedSlider(value: ColorPickerModes) {
        this._selectedSlider = value
    }

    constructor(
        private parseService: ParseService,
        private colorUtilsService: ColorUtilsService
    ) {}

    initialize(subscriptions: Subscription, initialColorHex: string | undefined) {
        const hexSlider = this.sliders.hex
        // const oldHex = hexSlider.hexInput
        hexSlider.hexInput = initialColorHex
        this.onHexColorInputChanged(hexSlider)
    }

    onHexColorInputChanged(slider: Slider) {
        let { hexInput = '' } = slider

        if (!hexInput.startsWith('#') && hexInput.length > 0) {
            hexInput = '#' + hexInput
        }

        if (hexInput.length > 7) {
            hexInput = hexInput.substring(0, 7)
        }

        slider.hexInput = hexInput
        // manually update the inputs since they're not bound to the ui when in hex mode
        slider.inputs = this.colorUtilsService.convertHexToRGBArray(hexInput)
        this.onSliderInputsChanged(slider)
    }

    onSliderInputsChanged(slider: Slider) {
        // compute output
        const output = this.computeSliderOutput(slider)
        this.colorOutput.next(output.color)

        // sync inputs
        for (const item of this.sliders.all) {
            if (item.mode === slider.mode) {
                continue
            }

            item.inputs = this.computeInputsFromOutput(item.mode, output)
            if (item.hexInput !== undefined) {
                // if check is not needed
                item.hexInput = output.rgbHex
            }
        }
    }

    getSliderInputID(index: number, obj: SliderInput) {
        return `${this.selectedSlider}-${obj.name}`
    }

    computeInputsFromOutput(mode: ColorPickerModes, output: SliderOutput) {
        switch (mode) {
            case 'cmyk':
                const cmyk = this.colorUtilsService.convertHexToCMYK(output.rgbHex)
                return [
                    {
                        name: 'Cyan',
                        value: cmyk.c,
                    },
                    {
                        name: 'Magenta',
                        value: cmyk.m,
                    },
                    {
                        name: 'Yellow',
                        value: cmyk.y,
                    },
                    {
                        name: 'Black',
                        value: cmyk.k,
                    },
                ]
            case 'rgb':
            case 'hex':
                return this.colorUtilsService.convertHexToRGBArray(output.rgbHex)
        }
    }

    computeSliderOutput(slider: Slider) {
        switch (slider.mode) {
            case 'cmyk':
                return this.colorUtilsService.fromCmyk(slider.inputs)
            case 'rgb':
            case 'hex':
                return this.colorUtilsService.fromRgb(slider.inputs)
        }
    }

    getAllColors() {
        const colorMap: Record<string, true | undefined> = {}
        const allColors: ChiliColorAll[] = []
        for (let c = 0; c <= 1.0; c += 0.25) {
            for (let m = 0; m <= 1.0; m += 0.25) {
                for (let y = 0; y <= 1.0; y += 0.25) {
                    for (let k = 0; k <= 1.0; k += 0.25) {
                        const r = 255 * (1 - c) * (1 - k)
                        const g = 255 * (1 - m) * (1 - k)
                        const b = 255 * (1 - y) * (1 - k)

                        if (colorMap[`${r}-${g}-${b}`]) {
                            continue
                        }
                        colorMap[`${r}-${g}-${b}`] = true
                        allColors.push({
                            type: 'ALL',
                            rgb: {
                                r,
                                g,
                                b,
                            },
                            hsl: this.colorUtilsService.convertRgbToHsl(r, g, b),
                            hex: this.colorUtilsService.convertRGBtoHex(r, g, b),
                        })
                    }
                }
            }
        }

        allColors.sort((colorA, colorB) => {
            if (colorA.hsl.h < colorB.hsl.h) {
                return 1
            }

            if (colorA.hsl.h > colorB.hsl.h) {
                return -1
            }

            if (colorA.rgb.r < colorB.rgb.r) {
                return 1
            }

            if (colorA.rgb.r > colorB.rgb.r) {
                return -1
            }

            if (colorA.rgb.g < colorB.rgb.g) {
                return 1
            }

            if (colorA.rgb.g > colorB.rgb.g) {
                return -1
            }

            return 0
        })

        return allColors
    }

    getSliderValues(): SliderValues {
        const cmyk: Slider = {
            mode: 'cmyk',
            hexInput: undefined,
            inputs: [
                {
                    name: 'Cyan',
                    value: 0,
                },
                {
                    name: 'Magenta',
                    value: 0,
                },
                {
                    name: 'Yellow',
                    value: 0,
                },
                {
                    name: 'Black',
                    value: 0,
                },
            ],
            min: 0,
            max: 100,
        }
        const rgb: Slider = {
            mode: 'rgb',
            hexInput: undefined,
            inputs: [
                {
                    name: 'Red',
                    value: 255,
                },
                {
                    name: 'Green',
                    value: 255,
                },
                {
                    name: 'Blue',
                    value: 255,
                },
            ],
            min: 0,
            max: 255,
        }
        const hex: Slider = {
            mode: 'hex',
            hexInput: '#FFFFFF',
            inputs: [
                {
                    name: 'Red',
                    value: 255,
                },
                {
                    name: 'Green',
                    value: 255,
                },
                {
                    name: 'Blue',
                    value: 255,
                },
            ],
            min: 0,
            max: 255,
        }
        return {
            cmyk,
            rgb,
            hex,
            all: [rgb, cmyk, hex],
        }
    }

    getQuickColors(): ColorQuickPick[] {
        return [
            { r: 246, g: 218, b: 179 },
            { r: 201, g: 148, b: 114 },
            { r: 89, g: 61, b: 44 },
            { r: 74, g: 38, b: 21 },
            { r: 37, g: 25, b: 15 },
            { r: 115, g: 28, b: 44 },
            { r: 186, g: 32, b: 41 },
            { r: 226, g: 32, b: 46 },
            { r: 241, g: 93, b: 88 },
            { r: 246, g: 158, b: 173 },
            { r: 252, g: 196, b: 167 },
            { r: 249, g: 164, b: 86 },
            { r: 243, g: 115, b: 47 },
            { r: 206, g: 93, b: 40 },
            { r: 90, g: 43, b: 29 },
            { r: 67, g: 26, b: 23 },
            { r: 168, g: 31, b: 39 },
            { r: 239, g: 55, b: 50 },
            { r: 245, g: 143, b: 152 },
            { r: 247, g: 175, b: 183 },
            { r: 255, g: 221, b: 155 },
            { r: 253, g: 188, b: 79 },
            { r: 248, g: 153, b: 31 },
            { r: 217, g: 124, b: 39 },
            { r: 129, g: 73, b: 37 },
            { r: 72, g: 25, b: 40 },
            { r: 170, g: 29, b: 68 },
            { r: 235, g: 8, b: 140 },
            { r: 239, g: 117, b: 173 },
            { r: 246, g: 175, b: 206 },
            { r: 254, g: 246, b: 169 },
            { r: 255, g: 209, b: 120 },
            { r: 246, g: 179, b: 26 },
            { r: 249, g: 201, b: 39 },
            { r: 192, g: 127, b: 42 },
            { r: 64, g: 32, b: 82 },
            { r: 106, g: 52, b: 132 },
            { r: 119, g: 65, b: 152 },
            { r: 161, g: 130, b: 187 },
            { r: 177, g: 158, b: 204 },
            { r: 254, g: 244, b: 140 },
            { r: 252, g: 240, b: 93 },
            { r: 252, g: 238, b: 33 },
            { r: 206, g: 220, b: 40 },
            { r: 154, g: 168, b: 57 },
            { r: 33, g: 29, b: 77 },
            { r: 84, g: 39, b: 101 },
            { r: 80, g: 90, b: 168 },
            { r: 127, g: 127, b: 189 },
            { r: 158, g: 156, b: 205 },
            { r: 215, g: 231, b: 171 },
            { r: 200, g: 221, b: 108 },
            { r: 153, g: 204, b: 106 },
            { r: 136, g: 179, b: 63 },
            { r: 110, g: 134, b: 56 },
            { r: 31, g: 37, b: 85 },
            { r: 19, g: 75, b: 150 },
            { r: 6, g: 109, b: 181 },
            { r: 39, g: 174, b: 229 },
            { r: 138, g: 213, b: 247 },
            { r: 176, g: 218, b: 172 },
            { r: 84, g: 187, b: 111 },
            { r: 1, g: 176, b: 83 },
            { r: 1, g: 93, b: 49 },
            { r: 13, g: 63, b: 34 },
            { r: 0, g: 124, b: 143 },
            { r: 0, g: 169, b: 162 },
            { r: 54, g: 193, b: 208 },
            { r: 129, g: 207, b: 207 },
            { r: 151, g: 216, b: 224 },
            { r: 255, g: 255, b: 255 },
            { r: 237, g: 237, b: 237 },
            { r: 222, g: 222, b: 222 },
            { r: 202, g: 202, b: 202 },
            { r: 167, g: 163, b: 164 },
            { r: 137, g: 133, b: 134 },
            { r: 102, g: 99, b: 100 },
            { r: 84, g: 80, b: 80 },
            { r: 58, g: 56, b: 56 },
            { r: 52, g: 50, b: 50 },
            // { r: 36, g: 36, b: 36 },
            // { r: 31, g: 30, b: 30 },
            // { r: 16, g: 19, b: 19 },
            { r: 0, g: 0, b: 0 },
        ].map((rgb) => {
            return {
                type: 'QUICK',
                rgb,
            }
        })
    }
}

type PickerType = 'all' | 'quick' | 'custom'

@Component({
    selector: 'app-chili-color-picker',
    templateUrl: './chili-color-picker.component.html',
    styleUrls: ['./chili-color-picker.component.scss'],
    providers: [CustomPickerService],
    standalone: false,
})
export class ChiliColorPickerComponent implements OnInit, OnDestroy {
    @Input() initialColorHex?: string

    @Output() readonly colorSelected = new EventEmitter<ChiliColorAll>()
    @Output() readonly colorClicked = new EventEmitter()

    // @Input() allSelectedFrames: (Frame | FrameWithText)[] = []

    subscriptions: Subscription | undefined

    readonly allColors = this.custom.getAllColors()
    readonly quickColors = this.custom.getQuickColors()

    // customColor?: ChiliColor
    // changedFrame = false

    // initialColor

    selectedPickerType: PickerType = 'quick'

    readonly pickerTypes: {
        type: PickerType
        label: string
    }[] = [
        {
            type: 'quick',
            label: 'Quick Picks',
        },
        {
            type: 'all',
            label: 'All Colors',
        },
        {
            type: 'custom',
            label: 'Custom',
        },
    ]

    constructor(
        private parseService: ParseService,
        public custom: CustomPickerService
    ) {}

    ngOnInit() {
        const subscriptions = new Subscription()
        this.subscriptions = subscriptions

        this.custom.initialize(subscriptions, this.initialColorHex)

        subscriptions.add(
            this.custom.colorOutput
                .pipe(
                    distinctUntilChanged(),
                    // use throttle instead of debouce to make the ui more snappy
                    throttleTimeLeadingAndTrailing(200)
                )
                .subscribe((output) => {
                    this.colorSelected.emit(output)
                })
        )
    }

    ngOnDestroy() {
        this.subscriptions?.unsubscribe()
        this.subscriptions = undefined
    }

    /**
     * The flow should be unidirectional:
     * color is clicked (quick pick or all color) => custom color is changed => color is then emitted
     *
     * This way, we're assured that the state of the sliders are always synchronized
     */
    onColorClicked(color: ChiliColorAll | ColorQuickPick) {
        // simulate inputs changed in the custom color picker
        const slider = this.custom.sliders.rgb
        slider.inputs = [
            {
                name: 'Red',
                value: color.rgb.r,
            },
            {
                name: 'Green',
                value: color.rgb.g,
            },
            {
                name: 'Blue',
                value: color.rgb.b,
            },
        ]
        this.custom.onSliderInputsChanged(slider)
        this.colorClicked.emit() // can be listened to if we want to close the color picker on click
    }

    //#region utils
    getColorInUseID(index: number, obj: ColorInUse) {
        return obj.chili.raw.id
    }

    getQuickColorID(index: number, obj: ColorQuickPick) {
        return index
    }

    getAllColorID(index: number, obj: ChiliColorAll) {
        return obj.hex
    }
}
