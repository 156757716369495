<div class="wp-col" style="padding: 0 8px 8px 8px">
    <div style="margin-top: 16px">
        <button
            *ngFor="let item of pickerTypes"
            mat-flat-button
            class="color-type"
            style="padding: 0 8px"
            [ngClass]="{ selected: selectedPickerType === item.type }"
            (click)="selectedPickerType = item.type"
        >
            {{ item.label }}
        </button>
    </div>

    <div style="border-top: 1px solid #471b46cc; margin-top: -1px; width: 100%"></div>

    <div *ngIf="selectedPickerType === 'quick'" class="wp-row" style="flex-wrap: wrap">
        <div
            *ngFor="let color of quickColors; trackBy: getQuickColorID"
            class="color-item"
            [ngStyle]="{ backgroundColor: 'rgb(' + color.rgb.r + ',' + color.rgb.g + ',' + color.rgb.b + ')' }"
            (click)="onColorClicked(color)"
        ></div>
    </div>

    <div *ngIf="selectedPickerType === 'all'" style="max-height: 200px; flex-wrap: wrap; overflow: auto" class="wp-row">
        <div
            *ngFor="let color of allColors; trackBy: getAllColorID"
            class="color-item"
            [ngStyle]="{ backgroundColor: 'rgb(' + color.rgb.r + ',' + color.rgb.g + ',' + color.rgb.b + ')' }"
            (click)="onColorClicked(color)"
        ></div>
    </div>

    <ng-container *ngIf="selectedPickerType === 'custom'">
        <mat-radio-group [(ngModel)]="custom.selectedSlider" [color]="'primary'" class="color-radio-group" style="display: flex; margin-top: 16px; gap: 8px">
            <mat-radio-button value="cmyk"> CMYK </mat-radio-button>
            <mat-radio-button value="rgb"> RGB </mat-radio-button>
            <mat-radio-button value="hex"> HEX </mat-radio-button>
        </mat-radio-group>

        <mat-form-field appearance="outline" *ngIf="custom.selectedSlider === 'hex'" style="margin-top: 8px">
            <mat-label>Hex</mat-label>
            <input
                matInput
                type="text"
                style="text-transform: uppercase"
                [(ngModel)]="custom.sliders[custom.selectedSlider].hexInput"
                (input)="custom.onHexColorInputChanged(custom.sliders[custom.selectedSlider])"
            />
        </mat-form-field>

        <ng-container *ngIf="custom.selectedSlider !== 'hex'">
            <div *ngFor="let obj of custom.sliders[custom.selectedSlider].inputs; trackBy: custom.getSliderInputID" class="color-input-container">
                <div class="color-input-container-label">
                    {{ obj.name }}
                </div>

                <mat-slider color="primary" step="1" [min]="custom.sliders[custom.selectedSlider].min" [max]="custom.sliders[custom.selectedSlider].max">
                    <input matSliderThumb [(ngModel)]="obj.value" (change)="custom.onSliderInputsChanged(custom.sliders[custom.selectedSlider])" />
                </mat-slider>

                <mat-form-field appearance="outline">
                    <input
                        matInput
                        type="number"
                        [(ngModel)]="obj.value"
                        [max]="custom.sliders[custom.selectedSlider].max"
                        [min]="custom.sliders[custom.selectedSlider].min"
                        (input)="custom.onSliderInputsChanged(custom.sliders[custom.selectedSlider])"
                    />
                </mat-form-field>
            </div>
        </ng-container>
    </ng-container>
</div>
